const Banner = ({ src }) => {
  return (
    <img
      className="banner"
      src={src}
      alt="banner"
    />
  );
};

export default Banner;
