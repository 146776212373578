/* eslint-disable no-undef */
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from '../components/Banner';

const Home = () => {
  const nav = useNavigate();

  const handleSubmit = () => {
    nav('/about');
  };

  return (
    <Fragment>
      <Banner src={`${process.env.PUBLIC_URL}/images/banner.png`} />
      {/* <h1 onClick={handleSubmit}>Hello</h1> */}
    </Fragment>
  );
};

export default Home;
